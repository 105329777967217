import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "giant" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "giant-elcyklar---friheten-att-upptäcka-mer"
    }}>{`Giant Elcyklar - Friheten att Upptäcka Mer`}</h1>
    <p>{`Välkommen till vår samling av Giant elcyklar – din nyckel till oändliga cykeläventyr, både i stadsmiljö och på landsbygden. Här presenterar vi de olika serier av elcyklar från Giant, en av världens ledande cykeltillverkare känd för sina innovativa och högpresterande modeller. Oavsett om du söker en tillförlitlig pendlarcykel eller en robust elmountainbike för terrängen, har Giant något för dig. Låt oss utforska de populära serien och deras unika egenskaper.`}</p>
    <h2 {...{
      "id": "giant-fathom-e"
    }}>{`Giant Fathom E+`}</h2>
    <h3 {...{
      "id": "översikt"
    }}>{`Översikt:`}</h3>
    <p>{`Giant Fathom E+ serien är designad för äventyrare och terrängcyklister som söker ett kraftfullt och responsivt åk. Med en robust ALUXX Aluminium ram och en kraftfull Yamaha motor, är dessa elmountainbikes optimala för varierad terräng och utmanande stigar.`}</p>
    <h3 {...{
      "id": "nyckelfunktioner"
    }}>{`Nyckelfunktioner:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor`}</strong>{`: Yamaha motor ger stark prestanda och smidighet i kuperad terräng.`}</li>
      <li parentName="ul"><strong parentName="li">{`Batteri`}</strong>{`: EnergyPak 500 Wh för en räckvidd upp till 150 km per laddning.`}</li>
      <li parentName="ul"><strong parentName="li">{`Användning`}</strong>{`: Perfekt för terrängcykling och pendling i varierad miljö med olika underlag.`}</li>
    </ul>
    <h2 {...{
      "id": "giant-explore-e"
    }}>{`Giant Explore E+`}</h2>
    <h3 {...{
      "id": "översikt-1"
    }}>{`Översikt:`}</h3>
    <p>{`Explore E+ serien är Giant's mångsidiga alternativ perfekt för både pendlare och fritidscyklister. Designad med komfort och räckvidd i fokus, erbjuder dessa cyklar kraftfull assistans och långvarig batterilivslängd, idealiskt för längre cykelresor.`}</p>
    <h3 {...{
      "id": "nyckelfunktioner-1"
    }}>{`Nyckelfunktioner:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor`}</strong>{`: Kraftfull SyncDrive Sport-motor från Yamaha.`}</li>
      <li parentName="ul"><strong parentName="li">{`Batteri`}</strong>{`: EnergyPak 500 till 625 Wh, vilket ger en räckvidd upp till 205 km.`}</li>
      <li parentName="ul"><strong parentName="li">{`Komfort & Bekvämlighet`}</strong>{`: Ergonomisk design, smart assist-teknik och tillbehör som stänkskärmar och pakethållare gör den perfekt för daglig pendling och äventyr.`}</li>
    </ul>
    <h2 {...{
      "id": "vilken-giant-elcykel-passar-dig"
    }}>{`Vilken Giant Elcykel Passar Dig?`}</h2>
    <h3 {...{
      "id": "köpguide"
    }}>{`Köpguide:`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Användningsområde`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Om du föredrar `}<strong parentName="li">{`terräng och äventyr`}</strong>{`, välj `}<strong parentName="li">{`Fathom E+`}</strong>{` med sin robusta konstruktion och kraftfulla motor.`}</li>
          <li parentName="ul">{`För `}<strong parentName="li">{`pendling och långdistansutflykter`}</strong>{`, är `}<strong parentName="li">{`Explore E+`}</strong>{` serie optimal tack vare dess komfort och långa räckvidd.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Motor & Batterikapacitet`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`För längre turer krävs större batterikapacitet. Explore E+ erbjuder batterier upp till 625 Wh.`}</li>
          <li parentName="ul">{`Om motorstyrka är en prioritet för utmanande terräng, då ger Fathom E+ mer kraft med sina Yamaha motorer.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Komfort och Accessoarer`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`För extra komfort och förvaring under pendling, överväg cyklar med MIK-kompatibla system och extra tillbehör i Explore E+ serien.`}</li>
        </ul>
      </li>
    </ol>
    <p>{`Upptäck vilken Giant elcykel som kan förvandla din vardagliga cykling till en upplevelse utöver det vanliga. För oavsett serie, kännetecknas alla deras elcyklar av hög prestanda och tillförlitlighet, vilket gör dem till kloka val för både vardagsbrukare och passionerade cykelentusiaster. Anpassa ditt val efter dina behov och upplev friheten som bara en Giant elcykel kan erbjuda.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      